@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;1,100&display=swap');

.Container {
  line-height: 1.5;
  font-family: 'Roboto', sans-serif;
  color: #3f3f3f;
  padding: 20px;
  margin: 0 auto;
  margin-top: 4em;
  max-width: 600px;
  @media screen and (min-width: 700px) {
    width: 60%;
    padding: 40px;  
  }
}

.H1 {
  
  margin-bottom: 0.35em;
  font-size: 2em;  
  line-height: 1.5;
}

.H2 {
  margin-bottom: 0.35em;
  font-size: 1.7em;
  line-height: 1.5;
}

.H3 {
  margin-bottom: 0.35em;
  font-size: 1.5em;
  line-height: 1.5;
}

.A {
  text-decoration: none;
  font-size: 1em;
}

.Paragraph {
  margin-top: 0.35em;
  margin-bottom: 0.5em;
  font-size: 1em;
}

.Ul {
  margin-bottom: 1.5em;
  padding-left: 2em;
  list-style-type: disc;
}

.Ol {
  margin-bottom: 1.5em;
  padding-left: 2em;
  list-style-type: decimal;
}

.Li {
  font-size: 1em;
  font-family: Roboto Normal, Arial, Helvetica, sans-serif;
}
